/************* Partner Page Banner CSS  *************/
.partnerPage-banner .partnerPage-header {
    background-image: url(../assets/img/partner-banner-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 500px 0 0 0;
}

.partnerPage-banner .partnerPage-header h1 {
    color: #fff;
    background-color: #121212;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
}


/************* Partner Page Partner Section CSS  *************/
.partner-page .partner-section .img-bx {
    border: 1px solid #d9d9d9;
    padding: 5px 2px;
    cursor: pointer;
}

.partner-page .partner-section {
    padding-bottom: 30px;
}