/************* FAQ Banner CSS  *************/
.faq .faq-banner .faq-header {
    background-image: url(../assets/img/become-part-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 500px 0 0 0!important;
}

.faq .faq-banner .faq-header h1 {
    color: #fff;
    background-color: #121212;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
}

.faq-icon {
    width: 4%;
    margin-right: 20px;
}

.faq-header {
    font-size: 30px;
    color: #fff;
    border-radius: 5px;
    padding: 4px 2px;
}

/************* FAQ Card CSS  *************/
.faq-bx {
    width: 100%;
}

.faq-section {
    margin: 30px auto;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #FFFF00;
}