/************* Route 29 CSS  *************/

.route-29 h5 {
    width: 100%; 
    text-align: end; 
    border-bottom: 4px solid #fff; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    font-size: 50px;
    letter-spacing: 20px;
    margin-top: 100px;
    margin-bottom: 70px;
    color: #fff;
    font-weight: 700;
}

.route-29 h5 span {
    background: #121212; 
    padding:0 60px; 
}




/************* Rout29 Banner CSS  *************/
.route29-banner .route29-header {
    background-image: url(../assets/img/race-kit-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 500px 0 0 0;
}
.route29-banner .route29-header h1 {
    color: #fff;
    background-color: #121212;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 80px;
}

.route-29 .route29-banner h3 {
    font-size: 35px;
    color: #FFFF00;
    font-weight: 700;
}

.route-29 .route29-banner ul li {
    font-size: 25px;
    line-height: 1.5;
}

.route-29 .route-29-banner ul p {
    font-size: 20px;
}

.route-29 .route29-banner table {
    width: 100%;
    font-size: 25px;
    line-height: 3;
    text-align: center;
}

.route-29 .route29-banner table, th, td {
    border: 2px solid #fff;
    border-collapse: collapse;
}

.route-29 .route29-banner table th {
    background-color: #FFFF00;
    color: #000;
}

/************* Route Map CSS  *************/

.route-29 .route-map h2 {
    font-size: 80px;
    font-weight: 700;
    text-align: center;
}

.route-29 .route-map p {
    text-align: center;
    font-size: 20px;
}

.route-29 .route-map .route-map-bx {
    display: flex;
}

.route-29 .route-map .route-map-bx .map-logo {
    width: 10%;
    flex-direction: column;
}

.route-29 .route-map .route-map-bx h3 {
    border-bottom: 1px solid #FFF;
    padding-bottom: 10px;
    padding: 50px 20px;
    width: 100%;
}

.route-29 .route-map .route-map-bx h3 span {
    font-size: 35px;
    font-weight: 700;
}

.route-29 .route-map .route-map-bx .map-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.route-29 .route-map .route-map-bx .map-logo img {
    width: 100%;
    height: auto;
}

.route-29 .route-map .small-text {
    font-size: 15px;
    text-align: start;
}


/************* Route Gpx CSS  *************/
.route-29 .route-gpx .gpx-bx {
    margin: 50px auto;
}

.route29 .route-gpx .gpx-bx p {
    font-size: 25px;
}

.route-29 .route-gpx iframe {
    min-width: 100%;
    height: 700px;
    width: 1px;
    border: none;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.route-29 .route-gpx .gpx-map {
    padding-left: 50px;
}


/************* Route 29 Content  *************/
.route-29 .route-content table, th, td {
    border: 2px solid #fff;
    border-collapse: collapse;
}

.route-29 .route-content table {
    width: 100%;
    font-size: 25px;
}

.route-29 .route-content table th, td {
    padding: 30px 10px;
}

/************* Route 29 Spotlight  *************/

.route-29 .route-spotlight h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}

