/************* Route 104 CSS  *************/

.route-104 h5 {
    width: 100%; 
    text-align: end; 
    border-bottom: 4px solid #fff; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    font-size: 50px;
    letter-spacing: 20px;
    margin-top: 100px;
    margin-bottom: 70px;
    color: #fff;
    font-weight: 700;
}

.route-104 h5 span {
    background: #121212; 
    padding:0 60px; 
}




/************* Route 104 Banner CSS  *************/
.route104-banner .route104-header {
    background-image: url(../assets/img/race-kit-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 500px 0 0 0;
}
.route104-banner .route104-header h1 {
    color: #fff;
    background-color: #121212;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 80px;
}

.route-104 .route104-banner h3 {
    font-size: 35px;
    color: #FFFF00;
    font-weight: 700;
}

.route-104 .route104-banner ul li {
    font-size: 25px;
    line-height: 1.5;
}

.route-104 .route104-banner ul p {
    font-size: 20px;
}

.route-104 .route104-banner table {
    width: 100%;
    font-size: 25px;
    line-height: 3;
    text-align: center;
}

.route-104 .route104-banner table, th, td {
    border: 2px solid #fff;
    border-collapse: collapse;
}

.route-104 .route104-banner table th {
    background-color: #FFFF00;
    color: #000;
}

/************* Route Map CSS  *************/

.route-104 .route-map h2 {
    font-size: 80px;
    font-weight: 700;
    text-align: center;
}

.route-104 .route-map p {
    text-align: center;
    font-size: 20px;
}

.route-104 .route-map .route-map-bx {
    display: flex;
}

.route-104 .route-map .route-map-bx .map-logo {
    width: 10%;
    flex-direction: column;
}

.route-104 .route-map .route-map-bx h3 {
    border-bottom: 1px solid #FFF;
    padding-bottom: 10px;
    padding: 50px 20px;
    width: 100%;
}

.route-104 .route-map .route-map-bx h3 span {
    font-size: 35px;
    font-weight: 700;
}

.route-104 .route-map .route-map-bx .map-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.route-104 .route-map .route-map-bx .map-logo img {
    width: 100%;
    height: auto;
}


/************* Route Gpx CSS  *************/
.route-104 .route-gpx .gpx-bx {
    margin: 50px auto;
}

.route-104 .route-gpx .gpx-bx p {
    font-size: 25px;
}

.route-104 .route-gpx iframe {
    min-width: 100%;
    height: 700px;
    width: 1px;
    border: none;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.route-104 .route-gpx .gpx-map {
    padding-left: 50px;
}


/************* Route 104 Yellow Shirt  *************/
.yellow-shirt h2 {
    padding: 10px;
    font-size: 50px;
    font-weight: 700;
}

.yellow-shirt .title-icon {
    width: 8%;
    margin-right: 20px;
}

.yellow-shirt h3 {
    font-size: 34px;
    margin-bottom: 10px;
    font-weight: 700;
}
.yellow-shirt h4 {
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 400;
}

.yellow-shirt p {
    margin: 30px auto;
    font-size: 20px;
}

.yellow-shirt ul {
    margin-top: 40px;
    font-size: 20px;
    list-style: upper-alpha;
}

.yellow-shirt .yellow-shirt1 {
    width: 80%;
    height: auto;
}

.yellow-shirt h3 span.top {
    font-size: 80px;
    margin-left: 40px;
    position: relative;
    top: 30px;
    font-style: italic;
    font-weight: 700;
}

/************* Route 104 KOM  *************/
.KOM h2 {
    font-size: 45px;
    color: #FFFF00;

}
.KOM h2 span {
    font-size: 60px;
    margin-left: 20px;
    font-weight: 700;
}

.KOM .kom-bx {
    margin-top: 60px;
    margin-left: 35px;
}

.KOM .kom-bx p {
    font-size: 25px;
}

.KOM .kom-bx .small-text {
    font-size: 20px;
}

.KOM .kom-bx ul {
    font-size: 25px;
    margin: 40px;
    list-style: upper-alpha;
}

.KOM .kom-img1 {
    width: 60%;
    height: auto;
}

.KOM .kom-img2 {
    width: 80%;
    height: auto;
    position: relative;
    left: 250px;
    bottom: 200px;
}

@media only screen and (max-width:768px) {
    .KOM .kom-img1 {
        position: relative;
        left: 20%;
    }

    .KOM .kom-img2 {
        left: 10%!important;
        bottom: 0!important;
    }
}



/************* Route 104 Content  *************/
.route-104 .route-content table, th, td {
    border: 2px solid #fff;
    border-collapse: collapse;
}

.route-104 .route-content table {
    width: 100%;
    font-size: 25px;
}

.route-104 .route-content table th, td {
    padding: 30px 10px;
}

/************* Route 104 Spotlight  *************/

.route-104 .route-spotlight h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}

