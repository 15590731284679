/************* About Letape  CSS  *************/
.about-letape iframe {
    width: 100%;
    height: 650px;
}
.about-letape h2 {
    font-weight: 700;
}
.about-letape h3 {
    font-weight: 700;
}

/************* About Letape Banner CSS  *************/
.about-letape .about-letape-banner .about-letape-header {
    background-image: url(../assets/img/about-letape-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 500px 0 0 0;
}

.about-letape .about-letape-banner .about-letape-header h1 {
    color: #fff;
    background-color: #121212;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
}



.about-letape .about-letape-banner .banner-2 {
    background-image: url(../assets/img/about-letape-banner2-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-letape .about-letape-banner .banner-2 img {
    width: 60%;
    height: auto;
    position: relative;
    left: 30%;
    top: 100px;
}

.about-letape .about-letape-banner .banner-bx {
    padding: 200px 60px 40px;
}

.about-letape .about-letape-banner .banner-bx img {
    width: 26%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.about-letape .about-letape-banner .banner-bx .banner-des {
    line-height: 2.5;
}

.about-letape .about-letape-banner .banner-bx h3 {
    font-size: 45px;
    line-height: 1.5;
    font-weight: 700;
}

.about-letape .about-letape-banner .banner-bx p {
    font-size: 20px;
    font-weight: 500;
}

.about-letape .about-letape-banner .banner-bx hr {
    color: black;
    width: 50%;
    position: relative;
    left: 25%;
    border-top: 2px dashed #000;
}


.about-letape .about-letape-banner .bg-theme-color {
    background: #FFFF00;
}


/************* About Letape Data CSS  *************/
.about-letape .about-letape-data .data-bx {
    background: #fff;
    border: 1px solid #000;
    padding: 0 10px 0;
}

.about-letape .about-letape-data .data-bx h3 {
    -webkit-text-stroke: 2px #000;
    -webkit-text-fill-color: #FFFF00;
    font-size: 70px;
    font-weight: 700;
    position: relative;
    bottom: 50px;
    left: 10px;
    margin: 0;
}

.about-letape .about-letape-data .data-bx p {
    color: #000;
    font-size: 20px;
    position: relative;
    bottom: 25px;
}


/************* About Letape Description CSS  *************/
.about-letape .about-letape-description h3 {
    font-size: 50px;
}

.about-letape .about-letape-description h5 {
    font-size: 35px;
}

.about-letape .about-letape-description ul li {
    line-height: 3;
    font-size: 25px;
}

.about-letape .about-letape-description button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 20px;
    letter-spacing: 0.8px;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 15px 10px;
}

.about-letape .about-letape-description button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.about-letape .about-letape-description button:hover svg {
    margin-left: 25px;
}

.about-letape .about-letape-description .des-bx {
    background: #fff;
    margin-top: 40px;
}
.about-letape .about-letape-description .des-bx .des-txtx {
    padding: 10px 30px;
}

.about-letape .about-letape-description .des-bx h4 {
    color: #121212;
    font-size: 40px;
    font-weight: 700;
}

.about-letape .about-letape-description .des-bx p {
    color: #121212;

}

.about-letape .about-letape-description .des-bx img {
    width: 100%;
    height: auto;
}

/************* About Letape Photo Gallery CSS  *************/
.about-letape .about-letape-photogallery h2 {
    font-size: 60px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.about-letape .about-letape-photogallery .media iframe {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.about-letape .about-letape-photogallery .photo-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.about-letape .about-letape-photogallery .photo-slider .item img {
    width: 80%;
    margin: 30px auto 15px auto;
    cursor: pointer;
}

.about-letape .about-letape-photogallery .photo-slider .item .active-item img {
    width: 100%;
}

.custom-dot-list-style button {
    border: 1px solid #FFFF00;
    background: transparent;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: #fff;
}