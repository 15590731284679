

/************* Contact Banner CSS  *************/
.contact-banner .contact-header {
    background-image: url(../assets/img/contact-banner-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 500px 0 0 0;
}

.contact-banner .contact-header h1 {
    color: #fff;
    background-color: #121212;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
}

/************* Contact Page Form CSS  *************/
.contact-page .contactPage-form h2 {
    font-size: 80px;
    font-weight: 700;
    letter-spacing: 5px;
}

.contact-page .contactPage-form h3 {
    font-weight: 700;
}

.contact-page .contactPage-form h4 {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 10px;
}


.contact-page .contactPage-form .contact-btn {
    background-color: #fff;
    padding: 70px 10px;
    text-align: center;
}

.contact-page .contactPage-form .contact-btn h3 {
    color: #000;
}

.contact-page .contactPage-form h2 {
    font-size: 60px;
}


/************* Contact Page Info CSS  *************/
.contact-page .contactPage-info h2 {
    font-size: 50px;
    margin-bottom: 10px;
}

.contact-page .contactPage-info h3 {
    background-color: #fff;
    color: #121212;
    font-weight: 700;
    font-size: 36px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.contact-page .contactPage-info a {
    text-decoration: none;
}

.contact-page .contactPage-info span img {
    width: 15%;
    cursor: pointer;
}