/************* Event Banner CSS  *************/
.event-title {
    padding: 10px;
    font-size: 50px;
    font-weight: 700;
    width: 100%!important;
    border: none!important;
}
.event h2, .event h3 {
    font-weight: 700;
}

.event-title-5 {
    padding: 10px;
    font-size: 50px;
    width: 100%!important;
    border: none!important;
}

.event .event-title .title-icon {
    width: 8%!important;
    margin-right: 30px;
}

.event .event-info-col .event-info-icon {
    width: 19%;
    margin-left: 30px;
}

/************* Event Banner CSS  *************/
.event-banner .event-header {
    background-image: url(../assets/img/event-header-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 500px 0 0 0;
}

.event-banner .event-header h1 {
    color: #fff;
    background-color: #121212;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
}

.event .event-banner .event-banner-bx h2 {
    text-align: center;
}

.event .event-banner .event-banner-bx p {
    text-align: center;
    font-size: 20px;
    line-height: 2.5;
    width: 70%;
    position: relative;
    left: 15%;
}
.event .event-banner .event-banner-bx img {
    margin: 2px auto;
    width: 24%;
    position: relative;
    left: 38%;
}

/************* Event Info-1 CSS  *************/
.event-info {
    position: relative;
}
.event-info .event-info-col ul {
    margin: 40px auto;
}
.event-info .event-info-col p {
    font-size: 30px;
}
.event-info-col ul li {
    font-size: 35px;
}
@media only screen and (max-width:768px) {
    .event-info-col ul li{
        font-size: 25px!important;
    }
}

.event-info .register-col {
    margin: 10px 150px;
}

.ul-decimal li{
    list-style-type: decimal;
}

.event-info .event-info-col ul li a {
    text-decoration: underline;
}

.event .event-info .download-btn img {
    cursor: pointer;
}

/************* Event Division CSS  *************/
.event .event-division .nav.nav-pills {
    width: 73%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}
.event .event-division .nav.nav-pills .nav-item {
    width: 50%;
}
.event .event-division .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}
.event .event-division .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #FFFF00 -5.91%, #121212 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.event .event-division .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.event .event-division .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.event .event-division .nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
}
.event .event-division .nav-link#projects-tabs-tab-second {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}

.event .event-division .division-table-left {
    background: #e6e6e6;
    color: #121212;
    text-align: center;
    font-weight: 700;
}

.event .event-division .division-table-right {
    text-align: center;
    font-size: 20px;
}

.event .event-division .division-img-bx {
    background: #FFFF00;
    margin: 10px auto;
    padding: 10px;
}

.event .event-division .division-img-bx a {
    text-decoration: none;
}


.event .event-division .division-img-bx h4:hover {
    transform: scale(1.2);
}

.event .event-division .division-img-bx h4 {
    margin: 0;
    color: #121212;
    text-align: center;
}

.event .event-division .division-img-bx span img  {
    width: 13%;
    /* margin-right: 50px; */
}

/************* Race Village CSS  *************/
.event .race-village .village-icon {
    width: 12%;
    margin-right: 40px;
}
.event .race-village  h2 {
    font-size: 50px;
    font-weight: 700;
}

.event .race-village  h3 {
    font-size: 35px;
}

.event .race-village ul li {
    font-size: 25px;
    line-height: 3;
}
.event .race-village button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 20px;
    letter-spacing: 0.8px;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 15px 10px;
}

.event .race-village button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.event .race-village button:hover svg {
    margin-left: 25px;
}

.race-village .race-village-img-bx img {
    width: 60%;
    position: relative;
    left: 20%;
}

.race-village .race-village-bx {
    position: relative;
    left: 10%;
}

/************* Site Map CSS  *************/

.site-map h3 {
    width: 100%; 
    text-align: center; 
    border-bottom: 3px solid #FFFF00; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    font-size: 50px;
    letter-spacing: 20px;
    margin-top: 100px;
}

.site-map h3 span {
    background:#121212; 
    padding:0 10px; 
}

/************* Event Traffic CSS  *************/
.event-traffic h3 {
    font-size: 50px;
    padding: 30px;
}

.event-traffic ul li {
    font-size: 30px;
    letter-spacing: 2px;
    line-height: 2;
}

.event-traffic h2 {
    width: 100%; 
    text-align: center; 
    border-bottom: 3px solid #FFFF00; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    font-size: 50px;
    letter-spacing: 20px;
} 

.event-traffic h2 span { 
    background:#121212; 
    padding:0 10px; 
}

/************* Event Precaution CSS  *************/
.precaution h2 {
    font-size: 50px;
    letter-spacing: 5px;
}

.precaution h2 span img {
    width: 8%;
    margin: 20px 40px 20px 0;
}

.precaution p, .precaution ul {
    font-size: 20px;
}

.precaution ul li {
    font-size: 20px;
    line-height: 2;
}

.precaution table {
    width: 80%;
    margin: 20px auto;
}

.precaution table thead tr th {
    text-align: center;
    padding: 20px 10px;
    font-size: 30px;
    font-weight: 700;
    background-color: #FFFF00;
}

.precaution table tr td {
    font-size: 20px;
    text-align: center;
}

.precaution table, 
.precaution th, 
.precaution td {
    border: 3px solid #000;
    border-collapse: collapse;
}